.App {
  text-align: center;
  background: rgba(143, 128, 196, 0.5);
  height: 100vh;
  width: 100%;
  margin: auto;
  scroll-snap-align: end;
}
.App-inner {
  text-align: center;
  height: 89vh;
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.title {
  font-size: 36px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
  margin: auto;
  width: 100%;
  background: rgba(255, 255, 255, 0.486);
  margin-bottom: 1%;
}

.footer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 5vh;
  background: rgba(3, 3, 37, 0.486);
  color: white;
}

/* .reset-btn {
  border: none;
  width: 300px;
  height: 50px;
  border-radius: 7px;
  font-size: 20px;
  color: black;
  background: rgb(223, 219, 219);
  font-weight: bold;
  transition: color 0.3s;
  transition: background 0.3s ease;
}
.reset-btn:hover {
  background: rgb(95, 89, 89);
  color: white;
  transition: color 0.5s;
  transition: background 0.5s ease;
} */

@media only screen and (max-width: 700px) {
  .title {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin: auto;
    width: 100%;
  }
}
