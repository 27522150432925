.game-box {
  display: grid;
  height: 80%;
  width: 80%;
  grid-template-rows: 25% 25% 25% 25%;
}
.invalid-move {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 14%;
  margin-left: 26%;
  width: 20%;
  height: 40px;
  color: white;
  background: rgba(255, 0, 0, 0.644);
  border-radius: 30px;
  opacity: 0.5;
}
.row-container {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.row {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.row-item {
  height: 100%;
  border: 1px solid black;
  border-top: none;
  border-right: none;
}

.row-itemL {
  border-left: none;
}
.row-itemB {
  border-bottom: none;
}

.row-item:hover {
  background: rgba(49, 49, 189, 0.153);
  cursor: pointer;
}

.game-piece {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  font-size: calc(70px + (26 - 14) * ((100vw - 1200px) / (1600 - 300)));
}
