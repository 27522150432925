.main-select-container {
  height: 60%;
  width: 86%;
  max-width: 1000px;
  max-height: 900px;
  align-items: center;
  justify-content: center;
  background: rgba(13, 111, 202, 0.5);
  margin: auto;
  border-radius: 30px;
}
.title-select {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: bold;
  padding-top: 30px;
  color: white;
}
.game-select-container {
  display: flex;
  height: 95%;
  width: 100%;
  margin-top: -58px;
  align-items: center;
  justify-content: center;
}

.sel-btn {
  width: 100%;
  height: 120px;
  border-radius: 60px;
  border-top-right-radius: 20px;
  color: white;
  background: rgb(180, 98, 98);
  border: none;
  margin: 10px;
  transition: border-top-right-radius 0.8s ease;
}

.sel-btn:hover {
  border-radius: 80px;
  border-top-left-radius: 20px;
  color: white;
  background: rgb(46, 46, 71);
  border: none;
  margin: 10px;
  transition: border-top-left-radius 0.8s ease;
  cursor: pointer;
}

.sel-btn:active {
  background: rgb(92, 146, 92);
}

.btn-text {
  font-size: 20px;
  font-weight: bold;
}
