.box-container {
  display: flex;
  height: 65%;
  width: 90%;
  box-shadow: 10px 10px 10px 10px rgba(255, 0, 191, 0.11);
  max-width: 900px;
  max-height: 900px;
  min-height: 350px;
  align-items: center;
  justify-content: center;
  background: rgba(13, 111, 202, 0.5);
  margin: auto;
  border-radius: 30px;
}

.new-game {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid rgba(0, 0, 0, 0.205);
  /* border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%; */
  border-bottom: none;
  border-top: none;
  border-radius: 50px;
  font-weight: bold;
  font-size: 20px;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(
    rgba(0, 0, 255, 0.119),
    rgba(128, 0, 128, 0.14),
    rgba(255, 166, 0, 0.119)
  );
  height: 90px;
  width: 150px;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 20px;
}
.new-game:hover {
  background-image: linear-gradient(
    rgba(0, 0, 255, 0.219),
    rgba(128, 0, 128, 0.24),
    rgba(255, 166, 0, 0.219)
  );
}
.new-game:active {
  transform: translatey(2px);
}

.board-view-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin: auto;
  margin-top: 20px;
  width: 30%;
  border: 1px solid black;
  background: rgba(0, 0, 255, 0.466);
  color: white;
  height: 40px;
}
.board-view-button:hover {
  cursor: pointer;
  background: purple;
}
.board-exit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin: auto;
  margin-top: 20px;
  width: 20px;
  border: 1px solid black;
  background: rgba(0, 0, 255, 0.466);
  color: white;
  height: 20px;
}
.board-exit-button:hover {
  cursor: pointer;
  background: purple;
}
.game-over-container {
  width: 100%;
}
.points-game-over {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgba(255, 255, 0, 0.153),
    rgba(255, 0, 0, 0)
  );

  text-align: center;
}
.point-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  font-size: 30px;
  color: white;
  margin-bottom: 20px;
}

.end-button-container {
  display: flex;
  margin-top: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
}
